import { createTheme, responsiveFontSizes } from "@mui/material";
import { red, yellow } from "@mui/material/colors";

let theme = createTheme({
  typography: {
    fontFamily: ["Lato", "system-ui", "serif"].join(","),
    p: {
      fontSize: 21,
    },
    h1: {
      fontSize: 48,
      fontWeight: 500,
      marginTop: 16,
      marginBottom: 16,
    },
    h2: {
      marginTop: 32,
      marginBottom: 16,
      fontSize: 36,
      fontWeight: 500,
    },
    h3: {
      marginTop: 16,
      marginBottom: 16,
      fontSize: 24,
    },
    subtitle1: {
      marginTop: 16,
      marginBottom: 16,
      fontSize: 25,
    },
    body1: {
      fontSize: 20,
    },
  },
  palette: {
    primary: red,
    secondary: yellow,
  },
});

theme = responsiveFontSizes(theme, {});
export default theme;
